import { RouteRecordRaw } from 'vue-router'
import { List } from '@/plugins/menu-icon'

export default [
  {
    path: 'role-management',
    name: 'roleManagement',
    component: () => import('@/layout/Empty.vue'),
    meta: {
      title: '角色管理',
      order: '4',
      icon: List,
    },
  },
  {
    path: 'user-log',
    name: 'userLog',
    component: () => import('@/views/role-management/index.vue'),
    meta: {
      order: '4-1',
      title: '角色列表（原用户组）',
    },
  },
] as RouteRecordRaw[]
