import { RouteRecordRaw } from 'vue-router'

/**
 * @description 注册路由表
 * @param routes 路由列表
 * @param moduleMap 模块映射表
 */
export const regiserRoutes = (
  routes: RouteRecordRaw[],
  moduleMap: Record<string, { [key: string]: any }>
) => {
  // 当前环境
  const MODE = import.meta.env.MODE
  Object.values(moduleMap).forEach(({ default: route }) => {
    // 不是开发环境下，不注册test页面，跳过
    if (MODE !== 'development' && route.path === '/test') return 
    routes.push(route)
  })
}

/**
 * @description 合并路由
 * @param routes 路由列表
 * @param moduleMap 模块映射表
 */
export const concatRoutes = (
  routes: RouteRecordRaw[],
  moduleMap: Record<string, { [key: string]: any }>
) => {
  const moduleRoute = Object.values(moduleMap)
    .map(item => item.default)
    .flat() as RouteRecordRaw[]
  return [...routes, ...moduleRoute]
}
