import { RouteRecordRaw } from 'vue-router'
import { Avatar } from '@/plugins/menu-icon'

export default [
  {
    path: 'user',
    name: 'userManagement',
    component: () => import('@/layout/Empty.vue'),
    meta: {
      title: '用户管理',
      order: '3',
      icon: Avatar,
    },
  },
  {
    path: 'org-list',
    name: 'orgList',
    component: () => import('@/views/user-management/OrganizationList.vue'),
    meta: {
      title: '组织架构',
      order: '3-1',
    },
  },
  {
    path: 'user-role-detail',
    name: 'userRoleDetail',
    component: () => import('@/views/user-management/UserRoleDetail.vue'),
    meta: {
      title: '查看用户角色',
    },
  },
  {
    path: 'distribute-role',
    name: 'distributeRole',
    component: () => import('@/views/user-management/DistributeRole.vue'),
    meta: {
      title: '分配角色',
    },
  },
  {
    path: 'distribute-shop-ownership',
    name: 'DistributeShopOwnership',
    component: () => import('@/views/user-management/DistributeShopOwnership.vue'),
    meta: {
      title: '分配所有权',
    },
  },
] as RouteRecordRaw[]
