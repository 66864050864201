import { RouteRecordRaw } from 'vue-router'
import { List } from '@/plugins/menu-icon'

export default [
  {
    path: 'shop-ownership-management',
    name: 'shopOwnershipManagement',
    component: () => import('@/layout/Empty.vue'),
    meta: {
      title: '所有权管理',
      order: '6',
      icon: List,
    },
  },
  {
    path: 'shop-ownership-list',
    name: 'shopOwnershipList',
    component: () => import('@/views/shop-ownership-management/index.vue'),
    meta: {
      order: '6-1',
      title: '所有权列表',
    },
  },
] as RouteRecordRaw[]
