import { RouteRecordRaw } from 'vue-router'
import { concatRoutes } from '../register-route'

// 控制台子模块路由映射表
const consoleRouteMap = import.meta.globEager('../console-module/*.ts')

const consoleRouter = {
  path: '/console',
  name: 'console',
  component: () => import('@/layout/Index.vue'),
  children: [],
} as RouteRecordRaw

// 合并子模块路由
consoleRouter.children = concatRoutes(consoleRouter.children!, consoleRouteMap)

export default consoleRouter
