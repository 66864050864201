import type { RouteRecordRaw } from 'vue-router'

export default {
  indexRedirect: {
    path: '/',
    redirect: '/console/home',
  },
  errorPage: {
    path: '/:any(.*)',
    name: 'notFound',
    component: () => import('@/views/error/Index.vue'),
  },
} as DefaultRoute

interface DefaultRoute { 
  indexRedirect: RouteRecordRaw
  errorPage: RouteRecordRaw
}
