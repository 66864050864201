import { shallowRef } from 'vue'
import {
  Grid as GridBase,
  Avatar as AvatarBase,
  Lock as LockBase,
  List as ListBase,
  Coin as CoinBase,
  HomeFilled as HomeBase, 
} from '@element-plus/icons-vue'

export const Grid = shallowRef(GridBase)
export const Avatar = shallowRef(AvatarBase)
export const Lock = shallowRef(LockBase)
export const List = shallowRef(ListBase)
export const Coin = shallowRef(CoinBase)
export const HomeFilled = shallowRef(HomeBase)
