import { RouteRecordRaw } from 'vue-router'
import { MenuRecord } from '#/menu'
import { routes } from './index'

export default function () {
  // 启用 keep-alive 组件列表
  const keepNameList: string[] = []
  // 菜单列表
  const menuList: MenuRecord[] = []

  /**
   * @description 深度遍历路由
   * @param routes 路由列表
   */
  const deepRoutes = (routes: RouteRecordRaw[]) => {
    routes.forEach(item => {
      const { meta, children } = item
      if (meta?.keepName) {
        keepNameList.push(meta.keepName)
      }
      if (meta?.order) {
        // to-do: 需要排序进入 menuList
        toMenuList(meta.order, item)
      }
      if (children?.length) {
        deepRoutes(children)
      }
    })
  }

  // 排序验证正则
  const orderReg = /^\d(-\d)*$/

  /**
   * @description 排序追加菜单列表
   * @param order 菜单排序
   * @param route 当前路由对象
   */
  const toMenuList = (order: string, route: RouteRecordRaw) => {
    if (orderReg.test(order)) {
      let orders = order.split('-').map(item => +item - 1)
      // 多级菜单
      if (orders.length > 1) return parseOrder2Menu(orders, route)
      // 一级菜单
      createMenuItem(menuList, orders[0], route)
    }
  }

  /**
   * @description 解析路由构建菜单
   * @param menuOrders 菜单排序数组
   * @param route 当前路由对象
   */
  const parseOrder2Menu = (menuOrders: number[], route: RouteRecordRaw) => {
    const length = menuOrders.length - 1
    const [firstIndex, lastIndex] = [menuOrders[0], menuOrders[length]]
    let parent = (menuList[firstIndex] = menuList[firstIndex] || {})
    // 仅获取到上一级
    for (let i = 1; i < length; i++) {
      const c = (parent.children = parent.children || [])
      parent = c[menuOrders[i]] = c[menuOrders[i]] || {}
    }
    // 获取当前实例
    const self = (parent.children = parent.children || [])
    createMenuItem(self, lastIndex, route)
  }

  /**
   * @description 生成菜单
   * @param arr 菜单数组
   * @param index 当前需创建菜单下标
   * @param route 当前路由对象
   */
  const createMenuItem = (arr: MenuRecord[], index: number, route: RouteRecordRaw) => {
    const child = arr[index]?.children
    arr[index] = route2Menu(route)
    child && (arr[index].children = child)
  }

  deepRoutes(routes)
  return { keepNameList, menuList }
}

/**
 * @description 路由转换菜单格式
 * @param route 路由对象
 * @param self  一级菜单对象
 * @returns 菜单对象
 */
function route2Menu(route: RouteRecordRaw): MenuRecord {
  const { path, meta } = route
  return {
    path: path,
    title: meta?.title,
    icon: meta?.icon,
    permission: meta?.permission,
  }
}
