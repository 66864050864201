import { RouteRecordRaw } from 'vue-router'
import { Coin } from '@/plugins/menu-icon'

export default [
  {
    path: 'ownership-management',
    name: 'ownershipManagement',
    component: () => import('@/layout/Empty.vue'),
    meta: {
      title: '所有权管理',
      // order: '5',
      icon: Coin,
    },
  },
  {
    path: 'action-list',
    name: 'actionList',
    component: () => import('@/views/ownership-management/ActionList.vue'),
    meta: {
      title: '操作权限',
      // order: '5-1',
    },
  },
  {
    path: 'field-list',
    name: 'fieldList',
    component: () => import('@/views/ownership-management/FieldList.vue'),
    meta: {
      title: '字段权限',
      // order: '5-2',
    },
  },
] as RouteRecordRaw[]
