import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import type { Component } from 'vue'
import NProgress from 'nprogress'
import { getLocalStorage } from '@/utils/storage'
import { regiserRoutes } from './register-route'
import deepRoutes from './deep-route'
import defaultRoute from './default-route'

NProgress.configure({ showSpinner: false })

// 获取路由模块映射表
const routeModuleMap = import.meta.globEager('./module/*.ts')

// 路由列表 -> 注入首页重定向
export const routes: Array<RouteRecordRaw> = [defaultRoute.indexRedirect]

// 注册路由
regiserRoutes(routes, routeModuleMap)

// 注入错误页
routes.push(defaultRoute.errorPage)

// 路由对象
const router = createRouter({
  history: createWebHistory(),
  routes,
})
export default router

// 路由跳转拦截 -> 无token重定向
router.beforeEach((to, from, next) => {
  NProgress.start()
  const token = getLocalStorage('token', null)
  if (!token && to.path !== '/login') {
    next('/login')
  } else {
    next()
  }
})

// 路由跳转完成 -> 关闭进度条
router.afterEach(() => {
  NProgress.done()
})

// 深度遍历路由 -> 获取启用 kepp-alive列表 及 菜单列表
const deepData = deepRoutes()
export const keepNameList = deepData.keepNameList
export const menuList = deepData.menuList

/**
 * 拓展 meta 类型，提供TS更好的推断W
 */
declare module 'vue-router' {
  interface RouteMeta {
    title: string // 标题
    keepName?: string // 启用keep-alive组件绑定的名字
    order?: string // 菜单排序
    icon?: Component // 图标
    permission?: string // 权限配置
  }
}
