import { RouteRecordRaw } from 'vue-router'
import { Grid } from '@/plugins/menu-icon'

export default [
  {
    path: 'application',
    name: 'applicationManagement',
    component: () => import('@/layout/Empty.vue'),
    meta: {
      title: '应用管理',
      order: '2', // menu所在位置 从1开始排序
      icon: Grid,
    },
  },
  {
    path: 'list',
    name: 'applicationList',
    component: () => import('@/views/application-management/AppList.vue'),
    meta: {
      title: '应用列表',
      order: '2-1',
    },
  },
] as RouteRecordRaw[]
