import { RouteRecordRaw } from 'vue-router'
import { HomeFilled } from '@/plugins/menu-icon'

export default [
  {
    path: 'home',
    name: 'home',
    component: () => import('@/views/home/Index.vue'),
    meta: {
      order: '1',
      title: '首页',
      icon:HomeFilled
    },
  },
] as RouteRecordRaw[]
